import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { Container, Accordion } from "react-bootstrap"

import { APREMIA_PHONE, APREMIA_PHONE_FORMATTED } from "../params"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import InsuranceDescription from "../components/insuranceDescription"
import FeatureList from "../components/featureList"
import QuoteForm from "../components/quoteForm"
import CallIcon from "../components/callIcon"

import InsuranceImage from "../images/stock-image-3.jpg"
import InsuranceImageMobile from "../images/stock-image-3-mobile.jpg"
import InsuranceImageTablet from "../images/stock-image-3-tablet.jpg"
import Stock1Src from "../images/stock-image-4.jpg"
import Stock2Src from "../images/stock-image-5.jpg"
import Stock3Src from "../images/stock-image-6.jpg"
import HealthcareSrc from "../images/icon-healthcare.svg"

const AccordionWrapper = styled.div((props) => ({
  background: `linear-gradient(to bottom, ${props.theme.colors["light-blue"]} 0%, ${props.theme.colors["light-blue"]}00 223px)`,
  borderRadius: "15px 15px 0 0"
}))

const AccordionHeading = styled.h2`
  flex: 1;
`

const Healthcare = styled(HealthcareSrc)`
  height: 167px;
  @media (max-width: 1024px) {
    height: 116px;
    width: 117px;
  }
`

const MedicalInsurance = () => {
  const { t } = useTranslation()

  const features = [
    {
      title: t("mi_f0_title"),
      description: t("mi_f0_desc"),
      href: null,
      image: Stock1Src,
      imageFirst: true,
    },
    {
      title: t("mi_f1_title"),
      description: t("mi_f1_desc"),
      href: '/obamacare-insurance/',
      image: Stock2Src,
      imageFirst: false,
    },
    {
      title: t("mi_f2_title"),
      description: t("mi_f2_desc"),
      href: '/private-insurance/',
      image: Stock3Src,
      imageFirst: true,
    },
  ]

  return (
    <Layout>
      <SEO title={t("medical_insurance")} />

      <InsuranceDescription
        title={t("medical_insurance_title")}
        subtitle={t("medical_insurance_subtitle")}
        content={t("medical_insurance_description")}
        actions={(
          <>
            <a href={`tel:${APREMIA_PHONE}`} className="btn btn-primary btn-sm">
              <CallIcon /> {APREMIA_PHONE_FORMATTED}
            </a>
            <a href="#quote-form" className="btn btn-primary btn-sm ms-2">
              {t("we_call_you")}
            </a>
          </>
        )}
        image={InsuranceImage}
        imageMobile={InsuranceImageMobile}
        imageTablet={InsuranceImageTablet}
      />

      <div className="bg-white py-md-6">
        <Container className="gx-0 gx-md-3">
          <FeatureList list={features} />

          {/* ----- Accordion ----- */}
          <AccordionWrapper className="mt-md-6 px-3 py-4 px-md-6 pt-md-6">
            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-4">
              <AccordionHeading className="order-1 order-md-0 m-0">{t("mi_benefits_heading")}</AccordionHeading>
              <Healthcare className="order-0 order-md-1 ms-md-8" />
            </div>
            
            <Accordion flush alwaysOpen>
              {(new Array(12).fill(undefined)).map((_, index) => (
                <Accordion.Item key={index} eventKey={index} className="equalize-padding-x">
                  <Accordion.Header>
                    <h3 className="d-none d-md-block m-0">{t(`mi_feat${index + 1}_title`)}</h3>
                    <h4 className="d-md-none m-0">{t(`mi_feat${index + 1}_title`)}</h4>
                  </Accordion.Header>
                  <Accordion.Body className="render-tags">
                    <div className="pb-5 pe-md-6 fw-light">
                      {t(`mi_feat${index + 1}_description`)}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </AccordionWrapper>
          {/* ----- Accordion (end) ----- */}
        </Container>
      </div>

      <QuoteForm bordered />
    </Layout>
  )
}

export default MedicalInsurance

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
